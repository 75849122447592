@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "tailwindcss/variants";

@import "./signin.pcss";

*, html {
  font-family: "Source Sans 3";
  /* letter-spacing: 0.4px; */
}

.page-container.blured {
  filter: blur(10px);

  &:before {
    background-color: rgba(0,0,0, 0.3);
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }
}